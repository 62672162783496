.skillSand{
     background-color: #EEE7D5;
     height: 450px;
     column-gap: 20px;
     padding: 60px 30px 30px 30px;
     /* width: 100%; */
     font-family: 'Roboto', sans-serif;
}

.divTop{
     display: flex;
     justify-content: center;
     font-size: 60px;
     font-weight: bold;
     font-family: 'Sriracha', cursive;
     color: #80654B;
}

.divTop :hover{
     color: #dcb24a;
     transition: 0.3s;
}

.divBottom{
     margin-top: 20px;
     display: flex;
     justify-content: center;
     gap: 20px;
}

.skillsLogo{
     display: flex;
     align-items: center;
     flex-direction: column;
}

.skillsTextLogo{
     font-size: 20px;
     display: flex;
     justify-content: center;
     font-weight: bold;
     margin-top: 10px;
}

/* -------- Responsive -------- */

@media only screen and (max-width: 480px) {
     .divBottom {
          flex-wrap: wrap;
       }
}

/* ----------------------------------------------------- */
@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* ----------------------------------------------------- */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
}

/* ----------------------------------------------------- */
@media only screen and (min-width: 1025px) and (max-width: 1600px) {
}