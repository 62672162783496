*{
margin: 0px;
padding: 0px;
box-sizing: border-box;
}

body{
margin: 0px;
padding: 0px;
width: 100%;
height: 100%;
}