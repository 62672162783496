.projectSky {
  background-color: #c3eefa;
  height: 700px;
  padding: 30px;
  column-gap: 20px;
  margin: 0px;
  padding: 0px;
}

.proDivTop {
  display: flex;
  justify-content: center;
  color: #1f618d;
  font-size: 60px;
  font-weight: bold;
  font-family: 'Sriracha', cursive;
  padding-top: 30px;
  /* font-family: 'Roboto', sans-serif; */
}

.proDivTop :hover {
  color: #0fb5f7;
  transition: 0.3s;
}

.proDivBottom {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.projectCard {
  width: 280px;
  height: 360px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px rgb(150, 200, 219);
  overflow: hidden;
  background-color: white;
  margin: 0 40px 40px;
}

.projectCardPic {
  height: 280px;
}

.projectCardText {
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  background-color: white;
}

.projectCardTextLeft {
  font-weight: bold;
}

.projectCardDes{
  margin-left: 10px;
  font-size: 0.9rem;
}


/* -------- Responsive -------- */

@media only screen and (max-width: 480px){
  .projectSky {
    height: 1000px;
    }
    .proDivBottom {
       flex-wrap: wrap;
    }
}

/* ----------------------------------------------------- */
@media only screen and (min-width: 481px) and (max-width: 768px){

}

/* ----------------------------------------------------- */
@media only screen and (min-width: 769px) and (max-width: 1024px){

}

/* ----------------------------------------------------- */
@media only screen and (min-width: 1025px) and (max-width: 1600px) {

}